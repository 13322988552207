import React from 'react'
import uuid from 'react-uuid';
import TemplateAccord from '../../../common/TemplateUtils/TemplateAccord';
import TemplateAdd from '../../../common/TemplateUtils/TemplateAdd';
import { TemplateConstants } from '../../../config/template-constants';
import MainSection from '../EditSection/MainSection';
import MainTemplateModal from '../TemplateModals/MainTemplateModal';
import NewSiteDetail from '../TemplateModals/NewSiteDetail';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { timestamp } from '../../../utils/services';

const DefaultDetails = (props) => {
    const {CategoryList, AllCategoryList, updateTemplate, categoryId, lineId} = props;
  // Accordtion
    const [expanded, setExpanded] = React.useState("");
    const unique_id = uuid();
    const small_id = unique_id;
    const [accordOpen, setAccordOpen] = React.useState(false)
    const [menu, setMenu] = React.useState(false)
    const [ActiveId, setActiveId] = React.useState(null)
    const [categoryList, setCategoryList] = React.useState([]);

    React.useEffect(() => { setCategoryList(CategoryList) }, [CategoryList]);

  // Create site
    const [siteName, setSiteName] = React.useState('')
    const [siteId, setSiteId] = React.useState(null)
    const [createNewSite, setCreateNewSite] = React.useState(false)

    const HandleCreateSite = () => {
      setCreateNewSite(!createNewSite)
      setSiteName('')
      setSiteId(null)
    }
    const stopActive = (event) => event.stopPropagation();

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : 'load');
      setAccordOpen(!accordOpen);
    };

    const OpenMenu = (event, id) => {
      stopActive(event)
      setMenu(!menu)
      setActiveId(id)
    }

    const CloseMenu = (event) => {
      stopActive(event)
      setMenu(!menu)
    }

    const AddSiteDetaill = () => {
      const addSite = AllCategoryList.template.categories[0]
      const dd=  { 
        line_input_control_id:small_id, 
        uuid:small_id,
        prompt: siteName,
        updated_at: timestamp(),
        values : [
          {
              value: "check 1",
              is_selected: false
          },
          {
            value: "check 2",
              is_selected: false
          },
          {
            value: "check 3",
              is_selected: false
          }
        ],
        type: siteId.id === 1 ? 'CheckBox' : siteId.id === 2 ? 'TextBox' : siteId.id === 3 && 'Temperature',

    }
    addSite.prompt === "Inspection Details" && addSite.lines[0].line_input_controls.push(dd)
    updateTemplate("noToast")
    HandleCreateSite()
    }

    const reorder = (data, startIndex, endIndex) => {
      const result = Array.from(data);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result 
    }

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
  
      const reorderedItems = reorder(
        categoryList,
        result.source.index,
        result.destination.index
      );
  
      setCategoryList(reorderedItems);
    }

  return (
    <>
         <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='DefaultDetails'>
          {(provided) => (
            <div
              ref={provided.innerRef}
            >
    {categoryList?.map((defaults, index) => {
      return (
        <Draggable
          draggableId={String(defaults.line_input_control_id)}
          key={defaults.line_input_control_id}
          index={index}
        >
          {(provided) => (
            <div
              key={index}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            >
              <TemplateAccord
                expanded={expanded}
                handleChange={(panel) => handleChange(panel)}
                accordOpen={accordOpen}
                selectedID={defaults.line_input_control_id}
                from={`DEFAULTS_${defaults.line_input_control_id}`}
                HeaderTitle={defaults.prompt}
                innerTemplate={
                  <MainSection
                    defaults={defaults}
                    AllCategoryList={AllCategoryList}
                    selectedID={defaults.line_input_control_id}
                    lineId={lineId}
                    categoryId={categoryId}
                    updateTemplate={updateTemplate}
                  />
                }
                actions={{
                  CloseMenu: (event) => CloseMenu(event),
                  stopActive: (event) => stopActive(event),
                  OpenMenu: (event, id) => OpenMenu(event, id),
                  ActiveId,
                  menu,
                }}
              />
            </div>
          )}
        </Draggable>
      );
      })}
                  </div>
          )}
        </Droppable>
      </DragDropContext>
    <TemplateAdd
    HandleCreateSite={HandleCreateSite}
    name={'Add New'}
    />
    <MainTemplateModal
    createNewSite={createNewSite}
    title={'New site detail'}
    type={'NewSite'}
    HandleCreateSite={HandleCreateSite}
    actions = {{
      AddSiteDetaill : () => AddSiteDetaill(),
    }}
    innerTemplate = {
      <NewSiteDetail
      siteValues = {{
        name :(name) => setSiteName(name),
        value : (value) => setSiteId(value),
        siteName,
        siteId
      }}
      />
    }
    />
    </>
  )
}

export default DefaultDetails