import { Button } from '@mui/base'
import React, { useState } from 'react'
import ColorPicker from 'react-pick-color';
import { apiCall } from '../../../utils/action';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, TextField } from '@mui/material'

function AddNewTag({ open, handleClose, GetCommentLibTagList }) {
    const [color, setColor] = useState("#000000");
    const [tagName, setTagName] = useState("");

    const handleAddTag = () => {
        const payload = { "name": tagName, "color": color };
        apiCall(
            (response) => {
                const { success, message } = response.data;
                if (success) {
                    handleClose();
                    GetCommentLibTagList();
                } else {
                    console.error("Update failed:", message);
                }
            },
            payload,
            "ADD_COMMENT_LIB_TAG"
        );
    }

    return (
        <Dialog className="dialog-container-comment-library" open={open} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
            <DialogTitle className="comment-library-dialog-title" sx={{ p: '16px !important', mb: 2 }}>
                New Tag
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2} xs={12} sx={{ padding: "24px 32px 24px 32px", }}>
                    <Grid item xs={6}>
                        <FormControl variant="standard" className="forms-control w-100">
                            <label
                                className="input-form-label"
                                id="categoryName"
                                style={{ fontSize: "15px", fontWeight: 600 }}
                            >
                                Tag Name
                            </label>
                            <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                                type="text"
                                onChange={(e) => setTagName(e.target.value)}
                                value={tagName}
                                name="Name"
                                sx={{ width: '625px !important', height: '40px', fontSize: '15px' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%', height: '100%' }} className="color-picker-container">
                            <ColorPicker
                                hideAlpha
                                color={color}
                                onChange={(color) => setColor(color.hex)}
                                style={{ width: '100%', height: '100%' }}
                                theme={{
                                    width: '352px',
                                    height: '600px !important',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            <Divider />
            <DialogActions className="comment-library-summary-action">
                <div className="comment-library-action-btn">
                    <Button className="cl-summary-cancel-btn" onClick={handleClose}>
                        CLOSE
                    </Button>
                    <Button
                        className="cl-add-summary-btn"
                        style={{ width: "130px !important" }}
                        onClick={handleAddTag}
                    >
                        SAVE
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default AddNewTag