import React, { useState } from 'react'
import { Grid } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import { timestamp } from '../../../utils/services';

const TextInput = ({AllCategoryList,updateTemplate, selectedID, data}) => {
  const defaultText = Array.isArray(data) ? "" : data
  console.log(defaultText)
  const [value, setValue] = useState(defaultText)
  const [edit, setEdit] = useState(false)
  const CateList = AllCategoryList.template.categories
  let insp = CateList.find((insp) => insp.is_inspection_details === 1)
  const linesdata = insp.lines
  const valData = linesdata.find((line) => line.line_input_controls[0].line_input_control_id === selectedID)
  

  const handleChange = (event) => {
    setEdit(true)
    setValue(event.target.value);
  };

  const auto = () => {
    valData.line_input_controls[0].values.push(value)
    valData.line_input_controls[0].data = value
    valData.line_input_controls[0].updated_at = timestamp()
    updateTemplate("noToast")
    // setEdit(false)
  }

  const bubble = () => {
    if(edit){
      auto()
    setEdit(false)

    }

  }
console.log(edit)
  return (
    <ClickAwayListener  mouseEvent="onMouseDown"
    touchEvent="onTouchStart" onClickAway={() =>bubble()}>
    <Grid
    className='temprature'
      sx={{ width: "100% !important", marginBottom: "30px"}}
    >
      <TextField
        sx={{ pl: "0px !important"}}
        className="input-textfield"
        fullWidth
        size="small"
        value={value}
        onKeyDown={(e) => e.keyCode === 13 && auto()}
        onChange={handleChange}
        defaultValue={"Default text"}
        variant="outlined"
      />
      </Grid>
      </ClickAwayListener>
  )
}

export default TextInput