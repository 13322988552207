import React, { useEffect, useState } from "react";
// import "../../../assets/styles/containers/_inspDetails.scss";
import "../../../assets/styles/containers/_templates.scss";
import "../../../assets/styles/containers/_templates2.scss";
import Typography from "@mui/material/Typography";
import CategoryDetails from "./CategoryDetails";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import TemplateAdd from "../../common/TemplateUtils/TemplateAdd";
import InspectionDetails from "./TemplateAccord/InspectionDetails";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditTemplateList from "../../components/templateListView/EditTemplateList";
import { GetExistingTemplates, RequestTemplateDetails, UpdateTemplateReq } from "../templateListView/action";
import { timestamp } from "../../utils/services";

const EditTemplate = (props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { templateId, templateName } = props;
  const [CategoryList, setCategoryList] = useState([]);
  const [AllCategoryList, setAllCategoryList] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [basedDetails, setBasedDetails] = useState([]);
  const [typeDetails, setTypeDetails] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const handleCloseNew = () => setAddNew(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [loadDetails, setLoadDetails] = useState(0);
  const [load, setLoad] = useState(0);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [outsideClick, setOutsideClick] = useState(false);
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const [templateRatingDetails, setTemplateRatingDetails] = useState([]);
  const [summary, setSummary] = useState("");
  const [newCatList, setNewCatList] = useState([]);
  const companyId = sessionStorage.getItem("company_id");
  const [renId, setRenId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(false);

  const checkActiveFocus = () => setIsFocused(true);

  const renamingId = (id) => {
    setRenId(id);
  };
  const version = location.state?.version;
  const getCategoryList = (loader) => {
    if (loader === 'noLoader') {
      setShowLoader(false)
    }
    else setShowLoader(true);

    const formdata = {
      template_id: location.state?.id,
    };
    apiCall(
      (response) => {
        const { status_code, data, message, success } = response.data;
        if (success) {
          setCategoryList(data?.template?.categories);
          setTemplateRatingDetails(data.template?.rating_data);
          setSummary(data.template.summary_disclaimer);
          setAllCategoryList(data);
          setShowLoader(false);
        } else {
          toast.error(message);
          if (status_code === 404) {
            navigate(`/templates/`);
            setShowLoader(false);
          }
        }
      },
      formdata,
      "GET_CATEGORY_LIST"
    );
  };

  const GetTemplateDetail = (loader) => {
    if (loader === 'noLoader') {
      setShowLoader(false);
    }
    else setShowLoader(true);
    const formdata = { company_id: 1, user_id: 2, role_id: 2, template_id: location.state?.id };

    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setTemplateDetails(data);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          navigate(`/templates/`);
        }
      },
      formdata,
      "TEMPLATE_DETAILS"
    );
  };

  const DiscardTemplateReq = () => {
    setShowLoader(true);
    const formdata = { company_id: 1, user_id: 2, role_id: 2, template_id: location.state?.id };

    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          navigate(`/templates/`);
          setShowLoader(false);
          toast.success(message);
        } else {
          console.error("else ", response.data.message);
        }
      },
      formdata,
      "DISCARD_TEMPLATE"
    );
  };

  const HandleOpenEditor = (type, open) => {
    setAddNew(open);
    setIsEdit(type);
    if (type === "edit") {
      setLoadDetails(loadDetails + 1);
    }
  };

  const PublishTemplate = async () => {
    setShowLoader(true);
    toast.dismiss();
    setShowLoader(true);
    AllCategoryList.user_id = 2;
    AllCategoryList.company_id = 1;
    AllCategoryList.role_id = 2;
    // updateTemplate("noToast");
    const formdata = { template_id: location.state?.id };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          // setLoad(load + 1);
          setRenId("");
          setShowLoader(false);
          setOutsideClick(false);
          setIsRename(false);
          setIsFocused(false);
          apiCall(
            (response) => {
              const { data, message, success } = response.data;
              if (success) {
                setShowLoader(false);
                toast.success(message);
                navigate(`/templates/`);
              } else {
                console.error("else ", response.data.message);
                toast.error(response.data.message);
              }
            },
            formdata,
            "PUBLISH_TEMPLATE"
          );
        } else {
          // toast.error(message);
          console.error("else ", message);
          setShowLoader(false);
        }
      },
      AllCategoryList,
      "UPDATE_TEMPLATE_DATA"
    );
  };

  const TogglerSwicth = () => {
    const formdata = { company_id: 1, user_id: 2, role_id: 2, template_id: location.state?.id };

    GetExistingTemplates((response) => {
      const { status_code, data, success, message } = response.data;
      if (success) {
        UpdateTemplateReq(
          (response) => {
            if (response.data.success) {
              // toast.success(response.data.message);
              // window.location.reload();
              setLoad(load + 1);
            } else {
              toast.error(response.data.message);
            }
          },
          {
            company_id: 1,
            user_id: 2,
            role_id: 2,
            template_id: data?.id,
            name: data?.name,
            description: data?.description,
            inspection_type_id: data?.inspection_type_id,
            default: data?.default === 0 ? 1 : 0,
            is_active: data.is_active,
            line_level_rating: data?.line_level_rating,
          }
        );
      } else {
        toast.error(message)
        if (status_code === 404) {
          navigate(`/templates/`);
        } else if (status_code === 400 && message === "You can't edit template details after published.") {
          navigate(`/templates/`);
        }
      }
    }, formdata);
  };

  const updateTemplate = (toastStatus, toastMessage, dynamicToastFlag, addTagToCategoryFunctionCall) => {
    toast.dismiss();
    setShowLoader(true);
    AllCategoryList.user_id = 2;
    AllCategoryList.company_id = 1;
    AllCategoryList.role_id = 2;
    AllCategoryList.updated_at = timestamp();
    apiCall(
      (response) => {
        const { status_code, data, message, success } = response.data;
        if (success) {
          if (toastStatus !== "noToast") {
            if (dynamicToastFlag) toast.success(toastMessage)
            else toast.success(message);
          }
          if(addTagToCategoryFunctionCall) {
            setShowLoader(true);
            addTagToCategoryFunctionCall()  
            setTimeout(() => {
              setLoad(load + 1);
              setShowLoader(false);
            }, 1000);
          }
          if(!addTagToCategoryFunctionCall) setLoad(load + 1);
          setRenId("");
          if (load === 0) LoadList();
          setShowLoader(false);
          setOutsideClick(false);
          setIsRename(false);
          setIsFocused(false);
        } else {
          toast.error(message);
          console.error("else ", message);
          setShowLoader(false);
          if (status_code === 404) {
            navigate(`/templates/`);
          } else if (status_code === 400 && message === "You can't edit template details after published.") {
            navigate(`/templates/`);
          }
        }
      },
      AllCategoryList,
      "UPDATE_TEMPLATE_DATA"
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  // commenting the below function call and deleting the function definition. 
  // Earlier get category list was a GET api call and it was called only once
  //  when template details page is loaded. Now category list is dynamic so
  //  get-category-list api is POST and it is called on every "add category button click"

  // useEffect(() => {
  //   getTemplateDetails();
  // }, []);

  const summaryEdit = () => {
    AllCategoryList.template.summary_disclaimer = summary.replace("\n", "");
    updateTemplate("showToast", "Summary Disclaimer has been saved", true);
  };

  const LoadList = (loader) => {
    getCategoryList(loader);
    GetTemplateDetail(loader);
  }

  useEffect(() => {
    LoadList()
  }, [load]);

  return (
    <>
      <div className="detail-card" draggable="false">
        <Preloader showPreloader={showLoader} />
        <div style={{ marginTop: isMobile && "25px" }} className="layout">
          <Grid container className="templates">
            <Grid item xs={6}>
              <Typography className="layout__title">
                {templateDetails?.name?.length > 50
                  ? `${templateDetails?.name?.substring(50, 0)}...`
                  : templateDetails?.name}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SettingsIcon
                id="template_setting_btn"
                onClick={() => HandleOpenEditor("edit", true)}
                className="templates__icon"
                sx={{ float: "right" }}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="layout__description">
                {templateDetails.description ?? ""}
              </p>
            </Grid>
          </Grid>
          <InspectionDetails
            isFocused={setIsFocused}
            newList={newCatList}
            setNewList={setNewCatList}
            checkActiveFocus={checkActiveFocus}
            renId={renId}
            setSkeletonLoad={setSkeletonLoad}
            skeletonLoad={skeletonLoad}
            renamingId={(id) => renamingId(id)}
            isChanged={isRename}
            activeRename={() => setIsRename(true)}
            updateTemplate={updateTemplate}
            CategoryList={CategoryList}
            AllCategoryList={AllCategoryList}
            LoadList={(value) => { setLoad(load + 1); LoadList(value) }}
            TriggerClick={() => setOutsideClick(true)}
            outsideClick={outsideClick}
            templateId={location?.state?.id}
            setShowLoader={setShowLoader}
          />
        </div>
        <div className="layout2">
          <CategoryDetails
            summaryEdit={summaryEdit}
            summary={summary}
            setSummary={setSummary}
            LoadList={() => setLoad(load + 1)}
            DiscardTemplateReq={DiscardTemplateReq}
            version={version}
            templateRatingDetails={templateRatingDetails}
            AllCategoryList={AllCategoryList}
            setAllCategoryList={setAllCategoryList}
            templateId={templateId}
            templateName={templateName}
            TogglerSwicth={TogglerSwicth}
            templateDetails={templateDetails}
            PublishTemplate={PublishTemplate}
            updateTemplate={updateTemplate}
          />
        </div>
      </div>
      <EditTemplateList
        open={addNew}
        isEdit={isEdit}
        typeDetails={typeDetails}
        setTypeDetails={setTypeDetails}
        setBasedDetails={setBasedDetails}
        basedDetails={basedDetails}
        templateId={location.state?.id}
        refresh={loadDetails}
        load={() => setLoad(load + 1)}
        onClose={handleCloseNew}
      />
    </>
  );
};

export default EditTemplate;